export class WhitelistedDomainService {
    /**
     * return true if hostname is part of whitelisted domain or localhost
     * @param whitelistedDomains - allowed domain set in setting app
     */
    static verifyDomain(whitelistedDomains: string[]): boolean {
        const hostname: string = window.location.hostname;
        const isLocalhost: boolean = (whitelistedDomains.indexOf(hostname) !== -1);

        if (isLocalhost || this.isLocalhost(window.location.hostname)) {
            console.log('app is accessed from localhost');
            return true;
        }
        return false;
    }

    static isLocalhost(address: string): boolean {
        const localhostAddress: Array<string> = [
            'localhost',
            '10.0.2.2'
        ];

        return localhostAddress.some(local => address === local);
    }
}