import { DataExchangeEventTypes } from '../Enums/dataExchangeEventType.enums';
import Constants from '../constants';
import { Consent } from '../dataTypes/type-consent';

/**
 * Class containing functions related to the dataExchange.
 * Please set dataExchangeArray and consentsService when you initialize an object of this class else you may receive errors.
 */
export default class DataExchangeService {
    public dataExchangeArray: any[] = [];

    //this is a global window event that is used for type 4 events. The same event is reused again and again.
    private globalWindowEvent;

    constructor() {
        this.globalWindowEvent = document.createEvent('HTMLEvents');
    }

    /**
     *
     * @param categories
     * This parameter contains the categories with the consents inside set to their status
     *  whether they are true or false.
     *
     * This function is used to create and push an event into the data exchange with event CONSENT_INITIALIZED
     * the usc origin is USC_ORIGIN_LOCAL
     * the categories passed here are the ones from the local storage.
     * This function uses generateConsentsArrayFromCategories which it then passes to
     * createConsentInitializedObject which it then passes to
     * dispatchEvent
     *
     * OtherConsents are for consents that are not there in any of the categories like the optin consent.
     * 
     * @yash I dont understand this function at all
     */
    public consentInitializedFromStorage(
        consents, templates
    ) {
        const object = DataExchangeService.createConsentInitializedObject(
            Constants.USC_ORIGIN_LOCAL,
            consents,
            templates
        );
        this.dispatchEvent(
            DataExchangeEventTypes.CONSENTS_INITIALIZED_STORAGE,
            object
        );
    }

    /**
     *
     *  @param categories
     *
     * This function is used to create and push an event into the data exchange with event
     * CONSENT_INITIALIZED
     * the usc origin is USC_ORIGIN_SERVER
     * the categories passed here are the ones from the server setting.
     * This function uses generateConsentsArrayFromCategories and creates the object using
     * createConsentInitializedObject which it then passes to
     * dispatchEvent
     *
     * OtherConsents are for consents that are not there in any of the categories like the opti
     * consent.
     */
    public dispatchConsentsInitializedEvent(consents, templates) {
        const object = DataExchangeService.createConsentInitializedObject(
            Constants.USC_ORIGIN_SERVER,
            consents,
            templates
        );
        this.dispatchEvent(
            DataExchangeEventTypes.CONSENTS_INITIALIZED_SERVER,
            object
        );
    }

    /**
     *
     * @param uscOrigin
     * @param consents
     * This function is used to create a consent initialized event that has to be pushed into
     * data Exchange.
     * usc origin can be passed in as the argument.
     * The event name is DataExchangeEventTypes.CONSENTS_INITIALIZED
     * The consent status is set for all the consents that are passed in the consents array.
     */
    private static createConsentInitializedObject(uscOrigin: string, consents: any[], consentTemplates) {
        const cons = {
            usc_origin: uscOrigin,
            event: Constants.CONSENT_INITIALIZED_EVENT
        };
        const template = consentTemplates;
        consents.forEach(consent => {
            if (!template[consent.templateId] || !template[consent.templateId][consent.version]) {
                console.error('Consent ' + consent.templateId + ' with version ' + consent.version + ' not found');
                return;
            }
            template[consent.templateId][consent.version].dataProcessors.forEach(proc => {
                cons[proc] = consent.consentStatus;
            });
        });
        return cons;
    }

    public dispatchCategoryChangedEventByCategory(category: Consent.Category) {
        const eventWithoutName = {
            event: 'category_status_changed',
            category: category.label,
            slug: category.categorySlug,
            status: category.status ? true : false,
            isIntermediate: category.isIntermediateState ? true : false
        };

        const eventWithName = {
            event: 'specific_category_status_changed ' + category.categorySlug,
            category: category.label,
            slug: category.categorySlug,
            status: category.status ? true : false,
            isIntermediate: category.isIntermediateState ? true : false
        };

        this.dispatchEvent(
            DataExchangeEventTypes.CATEGORY_CHANGED,
            eventWithoutName
        );

        this.dispatchEvent(
            DataExchangeEventTypes.SPECIFIC_CATEGORY_CHANGED,
            eventWithName
        );
    }

    /**
     *
     * @param consent
     *
     * A single consent is passed to this function.
     * This function is used whenever a consent needs to be inserted into the data exchange.
     * The event type is CONSENT_CHANGED or SPECIFIC_CONSENT_CHANGED depending on
     * whether the objects in the array are with names or without names.
     * There are #numuberOfDataprocessors events for each consent for both types
     * of events CONSENT_CHANGED and SPECIFIC_CONSENT_CHANGED.
     * This function creates an array for both scenarios for a consent and uses
     *  dispatchEvent to push the
     * object into the dataexchange for each dataProcessor one by one.
     *
     */
    public dispatchEventForConsent(consent: Consent.Consent, consentTemplates: Consent.ConsentTemplates) {
        const arrayEventsWithoutNames = DataExchangeService.createConsentChangedEvents(
            consent,
            consentTemplates
        );
        arrayEventsWithoutNames.forEach(element => {
            this.dispatchEvent(
                DataExchangeEventTypes.CONSENT_CHANGED,
                element
            );
        });
        const arrayEventsWithName = DataExchangeService.createConsentChangedEventsWithName(
            consent,
            consentTemplates
        );
        arrayEventsWithName.forEach(element => {
            this.dispatchEvent(
                DataExchangeEventTypes.SPECIFIC_CONSENT_CHANGED,
                element
            );
        });
    }

    /**
     *
     * @param consent
     *
     * This function is used to create a consent changed object with the
     * event as DataExchangeEventTypes.CONSENT_CHANGED and 2) the consent status
     * Since this function works for each data processor so it returns an array.
     */
    private static createConsentChangedEvents(
        consent: Consent.Consent, templates: Consent.ConsentTemplates
    ): any[] {
        const csChanges = [];
        if (
            !consent ||
            !templates[consent.templateId] ||
            !templates[consent.templateId][consent.version]
        ) {
            console.error('Consent ' + consent.templateId + ' with version ' + consent.version + ' not found');
            return;
        }
        const consentTemplate = templates[consent.templateId][consent.version];
        consentTemplate.dataProcessors.forEach(dataProcessor => {
            const object = {
                [dataProcessor]: consent.consentStatus,
                event: Constants.CONSENT_CHANGED_EVENT
            };
            csChanges.push(object);
        });
        return csChanges;
    }

    /**
     *
     * @param consent
     *
     * This function is used to create a consent changed object with the event as
     * 1) DataExchangeEventTypes.CONSENT_CHANGED + name of the consent and 2) the consent status
     *
     */
    private static createConsentChangedEventsWithName(consent: Consent.Consent, consentTemplates: Consent.ConsentTemplates) {
        const csChanges = [];
        if (
            !consentTemplates[consent.templateId] ||
            !consentTemplates[consent.templateId][consent.version]
        ) {
            console.error('Consent ' + consent.templateId + ' with version ' + consent.version + ' not found');
            return;
        }
        const consentTemplate = consentTemplates[consent.templateId][consent.version];
        consentTemplate.dataProcessors.forEach(dataProcessor => {
            const dp = {
                [dataProcessor]: consent.consentStatus,
                event: Constants.CONSENT_CHANGED_EVENT + ' ' + dataProcessor
            };
            csChanges.push(dp);
        });
        return csChanges;
    }

    public dispatchConsentChangeFinishedEvent(consents: any[], consentTemplates) {
        const object = DataExchangeService.createConsentChangeFinishedEventForConsentArray(consents, consentTemplates);
        this.dispatchEvent(
            DataExchangeEventTypes.CONSENTS_CHANGED_FINISHED,
            object
        );
    }

    private static createConsentChangeFinishedEventForConsentArray(consents: any[], consentTemplates) {
        const csChange = {
            event: Constants.CONSENTS_CHANGED_FINISHED
        };
        for (const i in consents) {
            if (Object.prototype.hasOwnProperty.call(consents, i)) {
                const consent = consents[i];
                if (
                    !consentTemplates[consent.templateId] ||
                    !consentTemplates[consent.templateId][consent.version]
                ) {
                    console.error('Consent ' + consent.templateId + ' with version ' + consent.version + ' not found');
                    return;
                }
                const consentTemplate = consentTemplates[consent.templateId][consent.version];
                for (const j in consentTemplate.dataProcessors) {
                    if (Object.prototype.hasOwnProperty.call(consentTemplate.dataProcessors, j)) {
                        const dataProcessor = consentTemplate.dataProcessors[j];
                        csChange[dataProcessor] = consent.consentStatus;
                    }
                }
            }
        }
        return csChange;
    }

    /**
     * This function is used to push an object into the dataexchange.
     * It will first iterate over elements in the dataExchangeObject coming from the setting.
     * And then for each element it will follow a different process depending on the type.
     * Once it will find the type, it will check if the event exists in the event array.
     * It will then call the function to push the data for that particular type for that event if the event Exists.
     * Every type may follow a different procedure. that is why
     * You can also specify whether to perform a check for the event type
     */
    public dispatchEvent(eventName: string, objectToBePushed: any) {
        for (const i in this.dataExchangeArray) {
            if (Object.prototype.hasOwnProperty.call(this.dataExchangeArray, i)) {
                const dataExchangeArrayElement = this.dataExchangeArray[i];
                switch (dataExchangeArrayElement.type) {
                case 1:
                    if (
                        DataExchangeService.isEventInDataExchangeElementArray(
                            eventName,
                            dataExchangeArrayElement.events
                        )
                    ) {
                        this.pushDataIntoGivenDatalayer(objectToBePushed, dataExchangeArrayElement.names);
                    }
                    break;
                case 3:
                    if (
                        DataExchangeService.isEventInDataExchangeElementArray(
                            eventName,
                            dataExchangeArrayElement.events
                        )
                    ) {
                        this.updateGivenVariableWithData(objectToBePushed, dataExchangeArrayElement.names);
                    }
                    break;
                case 4:
                    if (
                        DataExchangeService.isEventInDataExchangeElementArray(
                            eventName,
                            dataExchangeArrayElement.events
                        )
                    ) {
                        this.triggerGivenEventWithData(objectToBePushed, dataExchangeArrayElement.names);
                    }
                    break;
                default:
                }
            }
        }
    }

    /**
     * If it exists, notify privacy proxy about consent changes
     * @param consents 
     */
    public static updatePrivacyProxy(consents: Consent.Consent[]) {
        if (typeof window.uc === 'undefined') {
            return;
        }

        consents.forEach(consent => {
            window.uc.setStatus(consent.templateId, consent.consentStatus);
        })
    }

    /**
     *
     * @param data - the data to be pushed into the dataExchange.
     * @param dataExchangeElement - This refers to a single element of the dataExchangeObjectArray
     * For type one what happens is that the names array of the dataExchangeElement is iterated upon.
     * For every name a window object will be created if it does not exist and the data will be pushed into it.
     */
    private pushDataIntoGivenDatalayer(data, names) {
        names.forEach(name => {
            const windowDataExchangeElementObject:any = window[name] || [];
            const nameArray:Array<any> = name.split('.');
            if (nameArray && nameArray.length > 1) {
                window[nameArray[0]] = window[nameArray[0]] || {} as any;
                this.setValueInSubObject(window, nameArray, data, true);
            } else {
                windowDataExchangeElementObject.push(data);
                window[name] = windowDataExchangeElementObject;
            }
        });
    }

    /**
     *
     * @param data
     * @param names
     * For type four , a window event is fired from the for all the names in the names array of the dataExchangeObjectArray.
     * For every name an event will be triggered that can be listened by the client.
     * The dom event name will be the name insidie the names array.
     * The event type will be inside the data object.
     */
    private triggerGivenEventWithData(data, names: string[]) {
        names.forEach(name => {
            this.globalWindowEvent.initEvent(name, true, true);
            this.globalWindowEvent.event = data.event;
            this.globalWindowEvent.data = data;
            window.dispatchEvent(this.globalWindowEvent);
        });
    }

    private updateGivenVariableWithData(data, names) {
        for (const i in names) {
            if (Object.prototype.hasOwnProperty.call(names, i)) {
                const name = names[i];
                const nameArray = name.split('.');
                if (nameArray && nameArray.length > 1) {
                    window[nameArray[0]] = window[nameArray[0]] || {} as any;
                    this.setValueInSubObject(window, nameArray, data);
                } else {
                    window[name] = data;
                }
            }
        }
    }

    setValueInSubObject(output, arr, data, isDataLayer?) {
        if (arr.length > 1) {
            output[arr[0]] = output[arr[0]] || {};
            const nextOutput = output[arr[0]];
            arr.shift();
            this.setValueInSubObject(nextOutput, arr, data, isDataLayer);
        }
        if (isDataLayer) {
            output[arr[0]] = output[arr[0]] || [];
            output[arr[0]].push(data);
        } else {
            output[arr[0]] = data;
        }
    }

    /**
     *
     * @param eventArray
     * @param array
     * This function is used to check whether the particular event exists in the event array.
     * Shall return true if the event exists.
     * Will return false if the event does not exist.
     */
    private static isEventInDataExchangeElementArray(event, eventArray) {
        return eventArray.indexOf(event) === -1 ? false : true;
    }
}
