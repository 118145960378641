export enum DataExchangeEventTypes {
  CONSENTS_INITIALIZED_STORAGE = 'consents_initialized_storage',
  CONSENTS_INITIALIZED_SERVER = 'consents_initialized_setting',
  CONSENTS_CHANGED_FINISHED = 'consents_changed finished',
  CONSENT_CHANGED = 'consents_changed',
  SPECIFIC_CONSENT_CHANGED = 'specific_consents_changed',
  all_consents_given_status = 'all_consents_given_status',
  CATEGORY_CHANGED = 'category_status_changed',
  SPECIFIC_CATEGORY_CHANGED = 'specific_category_status_changed'
}
