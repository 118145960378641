export default class Constants {
    public static readonly USC_ORIGIN_SERVER = 'from server setting';
    public static readonly USC_ORIGIN_LOCAL = 'from local storage';
    public static readonly CONSENT_INITIALIZED_EVENT = 'consents_initialized';
    public static readonly CONSENT_CHANGED_EVENT = 'consent_changed';
    public static readonly CONSENTS_CHANGED_FINISHED = 'consents_changed_finished';
    public static readonly all_consents_given_status = 'all_consents_given_status';

    public static readonly USERCENTRICS_CMP_ID = 5;

    public static readonly CMP_IAB_COOKIE_NAME_THIRD_PARTY = 'euconsent';

    public static readonly CONSENSU_BASE_URL = 'https://usercentrics.mgr.consensu.org';

    public static readonly BASE_URL = 'https://app.usercentrics.eu';

    public static readonly LATEST_CONSENT_STRING_VERSION = 1;

    public static readonly USERCENTRICS_STRING = 'usercentrics';

    public static readonly CMP_STRING = '__cmp';

    public static readonly CMP_IAB_COOKIE_NAME_FIRST_PARTY = 'eupubconsent';

    public static readonly EU_COUNTRIES = [
        'AT',
        'BE',
        'BG',
        'HR',
        'CY',
        'CZ',
        'DK',
        'EE',
        'FI',
        'FR',
        'DE',
        'GR',
        'HU',
        'IE',
        'IT',
        'LV',
        'LT',
        'LU',
        'MT',
        'NL',
        'PL',
        'PT',
        'RO',
        'SK',
        'SI',
        'ES',
        'SE',
        'GB'
    ];
}
